import React from 'react';

{/* <svg width="25" height="25" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.7367 1.75C9.20138 1.75 5.68774 4.14103 5.68774 10.6965V12.1047V13.5625V14.1863C5.42891 14.573 5.10156 15.1834 5.10156 16.1875C5.10156 17.8084 6.07654 18.7075 6.43457 19.0099C7.13101 20.8123 8.4187 22.3209 9.62866 23.4507C10.884 24.6228 11.9561 25.3438 12.4767 25.6134L12.4775 25.6143L12.8638 25.8125H15.1991L15.3888 25.7151L15.5853 25.6134C16.107 25.3436 17.178 24.6229 18.4334 23.4507C19.6435 22.3207 20.9311 20.8118 21.6274 19.009C21.9918 18.7019 22.9605 17.8068 22.9605 16.1866C22.9605 15.1738 22.555 14.5333 22.3127 14.1991V14V12.1039V10.6965C22.3127 8.61053 21.7168 5.33288 19.9287 5.33288L18.7367 1.75ZM8.60583 8.20056C8.60583 8.20056 11.2876 8.75 13.9695 8.75C16.6514 8.75 18.7367 8.20056 18.7367 8.20056C20.244 9.4926 20.5222 12.1556 20.5627 13.5437V14.4375V14.7639L20.7635 15.0074C20.8946 15.1657 21.2104 15.5373 21.2104 16.1866C21.2104 17.0764 20.3998 17.7946 20.3739 17.8153L20.373 17.8162L20.1594 17.9725L20.0688 18.2255C19.5454 19.6951 18.3741 21.1114 17.2388 22.1715C16.1034 23.2317 14.9185 23.9881 14.7812 24.0591V24.0582L14.7727 24.0625H13.2876L13.2816 24.0591C13.1433 23.9875 11.9586 23.2316 10.8232 22.1715C9.68787 21.1114 8.51663 19.696 7.99316 18.2263L7.90344 17.9725L7.68811 17.8153C7.65007 17.7845 6.85156 17.0751 6.85156 16.1875C6.85156 15.5016 7.26956 14.9288 7.23865 14.9664L7.43774 14.7246V14.4375V12.3936C7.50716 10.9151 7.76268 9.04372 8.60583 8.20056Z" fill={fill}/>
</svg> */}


const Male = ({ fill }) => {
    return (

        <svg width="25" height="25" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.7367 1.75C9.20138 1.75 5.68774 4.14103 5.68774 10.6965V12.1047V13.5625V14.1863C5.42891 14.573 5.10156 15.1834 5.10156 16.1875C5.10156 17.8084 6.07654 18.7075 6.43457 19.0099C7.13101 20.8123 8.4187 22.3209 9.62866 23.4507C10.884 24.6228 11.9561 25.3438 12.4767 25.6134L12.4775 25.6143L12.8638 25.8125H15.1991L15.3888 25.7151L15.5853 25.6134C16.107 25.3436 17.178 24.6229 18.4334 23.4507C19.6435 22.3207 20.9311 20.8118 21.6274 19.009C21.9918 18.7019 22.9605 17.8068 22.9605 16.1866C22.9605 15.1738 22.555 14.5333 22.3127 14.1991V14V12.1039V10.6965C22.3127 8.61053 21.7168 5.33288 19.9287 5.33288L18.7367 1.75ZM8.60583 8.20056C8.60583 8.20056 11.2876 8.75 13.9695 8.75C16.6514 8.75 18.7367 8.20056 18.7367 8.20056C20.244 9.4926 20.5222 12.1556 20.5627 13.5437V14.4375V14.7639L20.7635 15.0074C20.8946 15.1657 21.2104 15.5373 21.2104 16.1866C21.2104 17.0764 20.3998 17.7946 20.3739 17.8153L20.373 17.8162L20.1594 17.9725L20.0688 18.2255C19.5454 19.6951 18.3741 21.1114 17.2388 22.1715C16.1034 23.2317 14.9185 23.9881 14.7812 24.0591V24.0582L14.7727 24.0625H13.2876L13.2816 24.0591C13.1433 23.9875 11.9586 23.2316 10.8232 22.1715C9.68787 21.1114 8.51663 19.696 7.99316 18.2263L7.90344 17.9725L7.68811 17.8153C7.65007 17.7845 6.85156 17.0751 6.85156 16.1875C6.85156 15.5016 7.26956 14.9288 7.23865 14.9664L7.43774 14.7246V14.4375V12.3936C7.50716 10.9151 7.76268 9.04372 8.60583 8.20056Z" fill={fill}/>
        </svg>
        

);
};

export default Male; 


