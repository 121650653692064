import axios from 'axios';
import { loginHandlerForMobile, slientLoginHandlerForMobile } from './validator';
import { antdMessageError } from '../Components/common/antdMessageComponent';
import { removeDuplicateParams } from '../Components/user/utills';

export async function handleAuthCodeGeneration(code, redirectUrl, commanUrlValue, clientId, navigate, clientDetails) {
  const queryParams = new URLSearchParams(window.location.search);
  try {
    const codedata = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
      headers: { Authorization: `Bearer ${code}` },
    });
    console.log(redirectUrl, 'testing123');
    if (redirectUrl) {
      if (redirectUrl === 'https://conscent-app-sdk') {
        try {
          const dataformobile = {
            token: codedata.data.authCode,
            status: true,
          };
          loginHandlerForMobile(JSON.stringify(dataformobile));
        } catch (err) {
          console.log(err, 'error while sending data to mobile app');
        }
      } else {
        slientLoginHandlerForMobile(
          JSON.stringify({
            message: 'SLIENT_USER_LOGIN',
            authToken: codedata.data.authCode,
            statusCode: '200',
          }),
        );
        const fetchRedirectUrl = new URL(redirectUrl);
        fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
        if (commanUrlValue.origin === 'subsPage' && commanUrlValue.purchaseType === 'physical') {
          navigate(`/address?clientId=${clientId}&purchaseType=${commanUrlValue.purchaseType}`);
        } else {
          fetchRedirectUrl.searchParams.set('paywallId', commanUrlValue.paywallId);
          fetchRedirectUrl.searchParams.set('anonId', commanUrlValue.anonId);
          if (!fetchRedirectUrl.searchParams.has('clientId') && clientId) {
            fetchRedirectUrl.searchParams.set('clientId', clientId);
          }
          try {
            if (!fetchRedirectUrl.searchParams.has('deviceType') && queryParams.get('deviceType')) {
              fetchRedirectUrl.searchParams.set('deviceType', queryParams.get('deviceType'));
            }
            if (!fetchRedirectUrl.searchParams.has('mobileView') && queryParams.get('mobileView')) {
              fetchRedirectUrl.searchParams.set('mobileView', queryParams.get('mobileView'));
            }
            if (!fetchRedirectUrl.searchParams.has('inAppPurchase') && queryParams.get('inAppPurchase')) {
              fetchRedirectUrl.searchParams.set('inAppPurchase', queryParams.get('inAppPurchase'));
            }
          } catch (error) {
            console.error(error);
          }
          window.location.href = removeDuplicateParams(fetchRedirectUrl.href);
        }
      }
    } else {
      antdMessageError(' A redirect URL is required but not provided. Please check and try again.');
    }
  } catch (err) {
    console.log(err, 'error on generating auth code');
  }
}
