import React from 'react';
{/* <svg width="25" height="25" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.572 1.96875C5.84958 1.96875 5.55945 12.4357 2.36133 16.5062C2.36133 18.2505 7.0131 23.4837 11.665 24.647C12.5808 25.3595 13.5545 25.8125 14.572 25.8125C15.4945 25.8125 16.3809 25.4407 17.2209 24.8409C21.86 23.6615 26.2025 18.5394 26.2025 17.6692C26.2021 14.1797 24.1708 1.96875 14.572 1.96875ZM16.3169 7.4939L16.898 10.1104C18.1169 10.1104 19.0411 9.26568 19.4939 8.75256C20.0813 9.84718 20.3843 11.2218 20.3843 12.7268V14.7964L20.5971 15.0425C20.5971 15.0425 20.9662 15.4625 20.9662 16.2157C20.9662 17.1896 20.1493 17.8444 20.1493 17.8444L19.9391 18.0041L19.8519 18.2537C19.2798 19.897 18.3191 21.4023 17.297 22.4595C16.2749 23.5167 15.1807 24.0625 14.572 24.0625C13.9634 24.0625 12.87 23.5167 11.8479 22.4595C10.8258 21.4022 9.86489 19.8972 9.29297 18.2537L9.20581 18.0033L8.99561 17.8435C8.99561 17.8435 8.17871 17.1887 8.17871 16.2148C8.17871 15.4617 8.54785 15.0425 8.54785 15.0425L8.76062 14.7955V12.7268C8.76062 12.6269 8.7674 12.5299 8.77002 12.4311C8.80929 12.418 13.9999 10.6799 16.3169 7.4939Z" fill={fill}/>
</svg> */}
const Male = ({ fill }) => {
    return (

        <svg width="25" height="25" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.572 1.96875C5.84958 1.96875 5.55945 12.4357 2.36133 16.5062C2.36133 18.2505 7.0131 23.4837 11.665 24.647C12.5808 25.3595 13.5545 25.8125 14.572 25.8125C15.4945 25.8125 16.3809 25.4407 17.2209 24.8409C21.86 23.6615 26.2025 18.5394 26.2025 17.6692C26.2021 14.1797 24.1708 1.96875 14.572 1.96875ZM16.3169 7.4939L16.898 10.1104C18.1169 10.1104 19.0411 9.26568 19.4939 8.75256C20.0813 9.84718 20.3843 11.2218 20.3843 12.7268V14.7964L20.5971 15.0425C20.5971 15.0425 20.9662 15.4625 20.9662 16.2157C20.9662 17.1896 20.1493 17.8444 20.1493 17.8444L19.9391 18.0041L19.8519 18.2537C19.2798 19.897 18.3191 21.4023 17.297 22.4595C16.2749 23.5167 15.1807 24.0625 14.572 24.0625C13.9634 24.0625 12.87 23.5167 11.8479 22.4595C10.8258 21.4022 9.86489 19.8972 9.29297 18.2537L9.20581 18.0033L8.99561 17.8435C8.99561 17.8435 8.17871 17.1887 8.17871 16.2148C8.17871 15.4617 8.54785 15.0425 8.54785 15.0425L8.76062 14.7955V12.7268C8.76062 12.6269 8.7674 12.5299 8.77002 12.4311C8.80929 12.418 13.9999 10.6799 16.3169 7.4939Z" fill={fill}/>
        </svg>
        

);
};

export default Male; 
