import React from 'react';
import style from './index.module.scss';

function NewLoader({ message }) {
  return (
    <div className={style['loader-container']}>
      <div id={style['loader']}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p className={style['loader-text']}>{message}</p>
    </div>
  );
}

export default NewLoader;
