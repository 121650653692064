import React from 'react';

const Deleteuser = ({ fill }) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 13.6667H18.6667M16.6667 10C16.6667 10.394 16.5891 10.7841 16.4383 11.148C16.2875 11.512 16.0666 11.8427 15.788 12.1213C15.5094 12.3999 15.1787 12.6209 14.8147 12.7716C14.4507 12.9224 14.0606 13 13.6667 13C13.2727 13 12.8826 12.9224 12.5186 12.7716C12.1546 12.6209 11.8239 12.3999 11.5453 12.1213C11.2668 11.8427 11.0458 11.512 10.895 11.148C10.7443 10.7841 10.6667 10.394 10.6667 10C10.6667 9.20435 10.9827 8.44129 11.5453 7.87868C12.108 7.31607 12.871 7 13.6667 7C14.4623 7 15.2254 7.31607 15.788 7.87868C16.3506 8.44129 16.6667 9.20435 16.6667 10ZM8 21.4311V21.3333C8 19.8304 8.59702 18.3891 9.65973 17.3264C10.7224 16.2637 12.1638 15.6667 13.6667 15.6667C15.1696 15.6667 16.6109 16.2637 17.6736 17.3264C18.7363 18.3891 19.3333 19.8304 19.3333 21.3333V21.4302C17.6226 22.4605 15.6628 23.0034 13.6658 23C11.5938 23 9.65511 22.4267 8 21.4302V21.4311Z" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);
};

export default Deleteuser; 
