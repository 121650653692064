import React from 'react';

import loderStyle from './ctaLoader.module.css';

function CTALoader({ message }) {
  return (
    <div className={loderStyle.loader}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default CTALoader;
