import React from 'react';

{/* <path d="M3.49923 13.6667H12.4979C12.6245 13.667 12.7463 13.7152 12.8388 13.8017C12.9312 13.8883 12.9874 14.0067 12.9959 14.133C13.0045 14.2593 12.9648 14.3842 12.8848 14.4824C12.8049 14.5806 12.6907 14.6448 12.5652 14.6621L12.4979 14.6667H3.49923C3.37164 14.668 3.24839 14.6205 3.1547 14.5339C3.06102 14.4473 3.00399 14.3281 2.9953 14.2008C2.98661 14.0735 3.02691 13.9477 3.10796 13.8492C3.189 13.7506 3.30465 13.6868 3.43123 13.6707L3.49923 13.6667ZM7.92123 1.33474L7.99923 1.33008C8.16252 1.3301 8.32012 1.39005 8.44214 1.49855C8.56416 1.60706 8.64212 1.75658 8.66123 1.91874L8.66589 1.99674V10.3901L10.8619 8.19475C10.9767 8.07996 11.1294 8.01101 11.2914 8.00082C11.4535 7.99063 11.6136 8.03991 11.7419 8.13941L11.8046 8.19541C11.9193 8.31021 11.9883 8.46294 11.9985 8.62496C12.0087 8.78697 11.9594 8.94714 11.8599 9.07541L11.8046 9.13808L8.47323 12.4687C8.35852 12.5836 8.20583 12.6527 8.04381 12.663C7.88179 12.6733 7.72157 12.6242 7.59323 12.5247L7.53056 12.4694L4.19456 9.13808C4.07372 9.01838 4.0032 8.85703 3.99744 8.68705C3.99167 8.51706 4.05109 8.3513 4.16354 8.22369C4.27598 8.09608 4.43295 8.01628 4.60231 8.00061C4.77167 7.98494 4.94061 8.0346 5.07456 8.13941L5.13723 8.19475L7.33256 10.3867V1.99674C7.33258 1.83346 7.39253 1.67585 7.50104 1.55383C7.60954 1.43181 7.75906 1.35385 7.92123 1.33474Z" fill="#FF4080"/>
</svg> */}



const Download = ({ fill }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.49923 13.6667H12.4979C12.6245 13.667 12.7463 13.7152 12.8388 13.8017C12.9312 13.8883 12.9874 14.0067 12.9959 14.133C13.0045 14.2593 12.9648 14.3842 12.8848 14.4824C12.8049 14.5806 12.6907 14.6448 12.5652 14.6621L12.4979 14.6667H3.49923C3.37164 14.668 3.24839 14.6205 3.1547 14.5339C3.06102 14.4473 3.00399 14.3281 2.9953 14.2008C2.98661 14.0735 3.02691 13.9477 3.10796 13.8492C3.189 13.7506 3.30465 13.6868 3.43123 13.6707L3.49923 13.6667ZM7.92123 1.33474L7.99923 1.33008C8.16252 1.3301 8.32012 1.39005 8.44214 1.49855C8.56416 1.60706 8.64212 1.75658 8.66123 1.91874L8.66589 1.99674V10.3901L10.8619 8.19475C10.9767 8.07996 11.1294 8.01101 11.2914 8.00082C11.4535 7.99063 11.6136 8.03991 11.7419 8.13941L11.8046 8.19541C11.9193 8.31021 11.9883 8.46294 11.9985 8.62496C12.0087 8.78697 11.9594 8.94714 11.8599 9.07541L11.8046 9.13808L8.47323 12.4687C8.35852 12.5836 8.20583 12.6527 8.04381 12.663C7.88179 12.6733 7.72157 12.6242 7.59323 12.5247L7.53056 12.4694L4.19456 9.13808C4.07372 9.01838 4.0032 8.85703 3.99744 8.68705C3.99167 8.51706 4.05109 8.3513 4.16354 8.22369C4.27598 8.09608 4.43295 8.01628 4.60231 8.00061C4.77167 7.98494 4.94061 8.0346 5.07456 8.13941L5.13723 8.19475L7.33256 10.3867V1.99674C7.33258 1.83346 7.39253 1.67585 7.50104 1.55383C7.60954 1.43181 7.75906 1.35385 7.92123 1.33474Z" fill={fill}/>
</svg>

);
};

export default Download; 