import React from 'react';


// <svg width="16" height="16" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M0.857422 13L6.20142 7L0.857422 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>





const Arrow = ({ fill }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.857422 13L6.20142 7L0.857422 1" stroke={fill} stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
  };
  
  export default Arrow; 
