import React from "react";
// import style from "../styles/index.module.scss";
import style from "./index.module.scss"

function Loader({message}) {
  return (
    <div className={style["loader-container"]}>
      <div id={style["loader"]}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
     <p className={style["loader-text"]}>
      {message}
      </p>
    </div>
  );
}

export default Loader;
