export const eventType = {
  VIEW: 'VIEW',
  CLICK: 'CLICK',
  EXIT: 'EXIT',
};

export const eventLocation = {
  SSO_LOGIN: 'SSO_LOGIN',
  SAP: 'SAP',
  SSO_LOGIN_OTP: 'SSO_LOGIN_OTP',
  SSO_SIGNUP: 'SSO_SIGNUP',
  SSO_SIGNUP_OTP: 'SSO_SIGNUP_OTP',
  SSO_LOGIN_PASSWORD: 'SSO_LOGIN_PASSWORD',
  SSO_FORGET_PASSWORD: 'SSO_FORGET_PASSWORD',
  SSO_FORGET_PASSWORD_OTP: 'SSO_FORGET_PASSWORD_OTP',
  SSO_RESET_PASSWORD: 'SSO_RESET_PASSWORD',
  SSO_SIGNUP_SECONDARY_DETAILS: 'SSO_SIGNUP_SECONDARY_DETAILS',
  SSO_SIGNUP_SET_PASSWORD: 'SSO_SIGNUP_SET_PASSWORD',
};

export const clickAction = {
  MOBILE: 'MOBILE',
  EMAIL: 'EMAIL',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  SSO_LOGIN_BTN: 'SSO_LOGIN_BTN',
  SSO_LOGIN_PASSWORD_BTN: 'SSO_LOGIN_PASSWORD_BTN',
  SSO_SIGNUP_SET_PASSWORD_BTN: 'SSO_SIGNUP_SET_PASSWORD_BTN',
  SSO_SIGNUP_BTN: 'SSO_SIGNUP_BTN',
  SSO_LOGIN_OTP_BTN: 'SSO_LOGIN_OTP_BTN',
  SSO_SIGNUP_OTP_BTN: 'SSO_SIGNUP_OTP_BTN',
};
