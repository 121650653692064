import React from 'react';

const Subscriber = ({ fill }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 10.6364H16.5M15 13.5455H16.5M9 16.4545H16.5M9 19.3636H16.5M19.5 10.6364H22.875C23.496 10.6364 24 11.1251 24 11.7273V20.8182C24 21.3968 23.7629 21.9518 23.341 22.361C22.919 22.7701 22.3467 23 21.75 23M19.5 10.6364V20.8182C19.5 21.3968 19.7371 21.9518 20.159 22.361C20.581 22.7701 21.1533 23 21.75 23M19.5 10.6364V8.09091C19.5 7.48873 18.996 7 18.375 7H7.125C6.504 7 6 7.48873 6 8.09091V20.8182C6 21.3968 6.23705 21.9518 6.65901 22.361C7.08097 22.7701 7.65326 23 8.25 23H21.75M9 10.6364H12V13.5455H9V10.6364Z" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  );
};

export default Subscriber;
