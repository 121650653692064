import { message } from 'antd';

const customMessage = (type, content) => {
  return message[type]({
    content: content,
    style: {
      marginTop: '130px',
      borderRadius: '8px',
      color: type === 'error' ? '#721c24' : type === 'success' ? '#155724' : '#856404',
    },
  });
};

export const antdMessageError = (content) => {
  return customMessage('error', content);
};

export const antdMessageSuccess = (content) => {
  return customMessage('success', content);
};

export const antdMessageWarning = (content) => {
  return customMessage('warning', content);
};
