import React from 'react';

const button = ({ text, color, fnc, bcolor, boxshadow, textcolor, font,disable }) => {
  const buttonstyle = {
    background: color,
    margin: '10px auto',
    width: '90%',
    height: '44px',
    // color: '#E4E4E4',
    border: bcolor || 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bolder',
    color: textcolor || 'white',
    boxShadow: boxshadow || 'none',
    fontFamily: font || 'Roboto',
  };
  return (
    <button disable={disable} style={buttonstyle} onClick={() => fnc()}>
      {' '}
      {text}{' '}
    </button>
  );
};

export default button;
