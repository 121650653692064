import React, { useState, useEffect } from 'react';
import style from '../Style/subscription.module.css';
import Arrow from './icons/back Vector';
import Button from './button';
import axios from 'axios';
import { antdMessageError, antdMessageSuccess } from '../common/antdMessageComponent';
import { logOutHandlerForMobile } from '../../utills/validator';
import { Modal } from 'antd';
import { Input, Radio, Space } from 'antd';
import { ReactComponent as CrossIcon } from './icons/crossIcon.svg';
import { ReactComponent as Tick } from './icons/blank1.svg';

const Subscription = ({ setscreen, rightvaluepassed, color }) => {
  const textColor = '#00063d';
  const backgroundColor = '#f5f5ff';
  const highlightColor = '#7583ff';
  const inputfieldColor = '#e0e4ff';
  const fontstyle = 'poppins';

  const [data, setdata] = useState([]);
  const [Subscriptions, setsubscriptions] = useState([]);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [active, setActive] = useState([]);
  const [inActive, setInActive] = useState([]);
  const [rightvalue, setrightvalue] = useState();
  const [loading, setLoading] = useState(true);
  const [seeAllBenefits, setSeeAllBenefits] = useState(false);
  const [screen1, setScreen1] = useState(false);
  const [screen2, setScreen2] = useState(false);
  const [screen3, setScreen3] = useState(false);
  const [screen4, setScreen4] = useState(false);
  const [screen5, setScreen5] = useState(false);
  const [screen6, setScreen6] = useState(false);
  const [benefits, setBenefits] = useState();
  const [benefitArray, setbenefitArray] = useState();

  const closeAll = () => {
    setScreen1(false);
    setScreen2(false);
    setScreen3(false);
    setScreen4(false);
    setScreen5(false);
    setScreen6(false);
  };

  const [customReason, setCustomReason] = useState('');
  const [reason, setReason] = useState('Not using the service enough');
  const onChange = (e) => {
    setReason(e.target.value);
  };

  const subscriptionType = (type) => {
    if (type === 'ONE_TIME') {
      return 'One Time';
    } else {
      return 'Recurring';
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/get-loggedin-user-details`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });
      setdata(response.data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 404) {
        antdMessageError('It seems your account has been logged out. Please try logging in again');
        logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        window.parent.postMessage({ source: 'logout-from-user' }, '*');
      }
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (data?.userProfileCustomization?.showCancelSubscriptionButton === 'HIDE') {
      setShowCancelButton(false);
    } else {
      setShowCancelButton(true);
    }
  }, [data]);

  function formatDate(isoString) {
    const date = new Date(isoString);
    // Define options for toLocaleDateString
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    // Convert to the desired format
    return date.toLocaleDateString('en-GB', options);
  }

  const cancelSubscription = async (id) => {
    setLoading(true);
    if (localStorage.getItem('accessToken') !== null) {
      try {
        const response = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}/authorization/userSubscriptions/${id}/cancel`,
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        });
        if (response.status === 200) {
          antdMessageSuccess('SuccessFully Cancelled the Subscription');
          closeAll();
        }
      } catch (error) {
        antdMessageError(` Cannot Cancel the Subscription as ${error.response.data.message}`);
        console.log(error.response.data.message);
      } finally {
        setLoading(false);
      }
    } else {
      antdMessageError('It seems your account has been logged out, Please try logging in again.');
      window.parent.postMessage({ source: 'userDetailAction' }, '*');
    }
  };

  const getSubscription = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/list-user-subscriptions`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });
      setsubscriptions(response.data);
      setActive(response.data.active);
      setInActive(response.data.inactive);
    } catch (error) {
      console.log(error.message);
      antdMessageError('Cannot get user subscriptions');
    }
  };
  console.log(active, 'djhio');

  const FeedbackAPI = async () => {
    if (localStorage.getItem('accessToken') !== null) {
      try {
        const response = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}/user/feedback`,
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
          data: { email: data?.email, message: reason },
        });
        if (response.status === 201) {
          antdMessageSuccess('Feedback Submitted Successfully');
        } else {
          antdMessageError('Something went wrong');
        }
      } catch (error) {
        antdMessageError(error.response.data.message);
        console.log(error);
      }
    } else {
      antdMessageError('It seems your account has been logged out, Please try logging in again.');
      window.parent.postMessage({ source: 'userDetailAction' }, '*');
    }
  };

  useEffect(() => {
    fetchData();
    getSubscription();
  }, []);

  useEffect(() => {
    if (data?.userProfileCustomization?.drawerposition === 'RIGHT') {
      setrightvalue(0);
    } else {
      setrightvalue();
    }
  }, [data]);

  const [cancellationid, setCancellationId] = useState('');
  const cancellationTrigger = (item) => {
    setScreen1(true);
    setCancellationId(item?._id);
    setBenefits(item?.benefits);
  };

  useEffect(() => {
    if (benefits !== undefined) {
      function convertStringToArray(inputString) {
        // Split the string by commas and remove any extra spaces
        return inputString?.split(',')?.map((item) => item?.trim());
      }
      const inputString = benefits;
      const resultArray = convertStringToArray(inputString);
      setbenefitArray(resultArray);
    }
  }, [benefits]);

  const stay_feedback = () => {
    FeedbackAPI();
    closeAll();
  };
  const cancel_feedback = () => {
    FeedbackAPI();
    setScreen6(true);
  };

  return (
    <>
      {
        <Modal
          className={style['container']}
          closable={true}
          open={screen1}
          maskClosable={false}
          style={{
            fontSize: '20px',
            fontWeight: '700',
            height: '426px',
          }}
          title={'Are you sure you want to cancel your subscription?'}
          width={'360px'}
          onCancel={() => {
            closeAll();
          }}
          closeIcon={<CrossIcon />}
        >
          <div style={{ fontWeight: 'normal', padding: '20px' }}>
            <div style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px' }}>
              Hey {data?.name}, <br /> Sorry to see you go. Are you sure you want to cancel your subscription?
            </div>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
                margin: '20px 0 40px 0',
                background: '#F8F8FA',
                padding: '15px 10px',
                borderRadius: '7px',
              }}
            >
              <strong>Just a quick fact:</strong> {data?.userProfileCustomization?.quickFact}{' '}
            </div>
            <div
              onClick={() => {
                setScreen1(false);
              }}
              style={{
                cursor: 'pointer',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: data?.userProfileCustomization?.logOutButtonColor,
                color: '#fff',
                fontWeight: '400',
                margin: '0 0 8px 0',
              }}
            >
              Go Back
            </div>
            <div
              onClick={() => {
                setScreen2(true);
              }}
              style={{
                border: `1px solid ${data?.userProfileCustomization?.logOutButtonColor}`,
                cursor: 'pointer',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: data?.userProfileCustomization?.logOutButtonColor,
                fontWeight: '400',
                margin: '8px 0 0 0',
              }}
            >
              Cancel My Plan
            </div>
          </div>
        </Modal>
      }

      {
        <Modal
          className={style['container']}
          closable={true}
          open={screen2}
          maskClosable={false}
          style={{
            fontSize: '20px',
            fontWeight: '700',
            opacity: '',
            height: '426px',
          }}
          title={'Check this out, before cancelling'}
          width={'360px'}
          onCancel={() => {
            closeAll();
          }}
          closeIcon={<CrossIcon />}
        >
          <div style={{ fontWeight: 'normal', padding: '20px' }}>
            <div style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px', padding: '0' }}>
              Before you cancel, we'd like you to see what you'll lose with the cancellation of your subscription:
            </div>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
                margin: '20px 0 20px 0',
                background: '#F8F8FA',
                padding: '15px 10px',
                borderRadius: '7px',
              }}
            >
              <strong>Benefits</strong> <br />
              <ul style={{ listStyle: 'none' }}>
                {benefitArray?.slice(0, 3)?.map((e) => (
                  <li style={{ display: 'flex', alignItems: 'center', marginTop: '3px' }}>
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                      <Tick style={{ marginRight: '5px' }} />
                    </div>
                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{e}</div>
                  </li>
                ))}
                <li
                  style={{ textDecoration: 'underline', cursor: 'pointer', margin: '5px 0 0 20px' }}
                  onClick={() => {
                    setSeeAllBenefits(true);
                  }}
                >
                  {' '}
                  <strong>View all benefits</strong>{' '}
                </li>
              </ul>
            </div>
            <div
              onClick={() => {
                closeAll();
              }}
              style={{
                cursor: 'pointer',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: data?.userProfileCustomization?.logOutButtonColor,
                color: '#fff',
                fontWeight: '400',
                margin: '0 0 8px 0',
              }}
            >
              I want to stay subscribed
            </div>
            <div
              onClick={() => {
                setScreen3(true);
              }}
              style={{
                border: `1px solid ${data?.userProfileCustomization?.logOutButtonColor}`,
                cursor: 'pointer',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: data?.userProfileCustomization?.logOutButtonColor,
                fontWeight: '400',
                margin: '8px 0 0 0',
              }}
            >
              Cancel My Plan
            </div>
          </div>
        </Modal>
      }

      {
        <Modal
          className={style['container']}
          closable={true}
          open={screen3}
          maskClosable={false}
          style={{
            fontSize: '20px',
            fontWeight: '700',
            height: '426px',
            opacity: '',
          }}
          title={'Your subscription matters to us'}
          width={'360px'}
          onCancel={() => {
            closeAll();
          }}
          closeIcon={<CrossIcon />}
        >
          <div style={{ fontWeight: 'normal', padding: '20px' }}>
            <div style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px' }}>
              Your support means a lot.
              <br />
              Subscriptions help us provide good and unbiased journalism.
              <br />
              <p style={{ marginTop: '15px', position: 'relative' }}>
                If you still want to cancel your subscriptions, please share your feedback. It will help us improve our
                offering.
              </p>
            </div>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
                margin: '25px 0 40px 0',
                borderRadius: '7px',
              }}
            >
              From, <br />
              {'Partner name'} Team
            </div>
            <div
              onClick={() => {
                closeAll();
              }}
              style={{
                cursor: 'pointer',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: data?.userProfileCustomization?.logOutButtonColor,
                color: '#fff',
                fontWeight: '400',
                margin: '0 0 8px 0',
              }}
            >
              Keep my plan for now
            </div>
            <div
              onClick={() => {
                setScreen4(true);
              }}
              style={{
                border: `1px solid ${data?.userProfileCustomization?.logOutButtonColor}`,
                cursor: 'pointer',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: data?.userProfileCustomization?.logOutButtonColor,
                fontWeight: '400',
                margin: '8px 0 0 0',
              }}
            >
              Share Feedback
            </div>
          </div>
        </Modal>
      }

      {
        <Modal
          className={style['container']}
          closable={true}
          open={screen4}
          maskClosable={false}
          style={{
            fontSize: '20px',
            fontWeight: '700',
            height: '426px',
            opacity: '',
          }}
          title={'Your feedback will help us improve our subscriptions'}
          width={'360px'}
          onCancel={() => {
            closeAll();
          }}
          closeIcon={<CrossIcon />}
        >
          <div style={{ fontWeight: 'normal', padding: '10px 20px' }}>
            <div style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px' }}>
              Can you help us understand why you're cancelling?
            </div>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
                margin: '15px 0 20px 0',
                background: '#F8F8FA',
                padding: '15px 10px',
                borderRadius: '7px',
              }}
            >
              <Radio.Group onChange={onChange} value={reason}>
                <Space direction="vertical">
                  <Radio value={'Not using the service enough'}>Not using the service enough</Radio>
                  <Radio value={'Found a better alternative'}>Found a better alternative</Radio>
                  <Radio value={'Too expensive'}>Too expensive</Radio>
                  <Radio value={'Unhappy with the content'}>Unhappy with the content</Radio>
                  <Radio value={5}>
                    <span> Other... </span>
                    {reason === 5 ? (
                      <Input
                        style={{
                          width: 200,
                          marginInlineStart: 8,
                        }}
                        onBlur={() => {
                          setReason(customReason);
                        }}
                        onChange={(event) => {
                          setCustomReason(event.target.value);
                        }}
                      />
                    ) : null}
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
            <div
              onClick={stay_feedback}
              style={{
                cursor: 'pointer',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: data?.userProfileCustomization?.logOutButtonColor,
                color: '#fff',
                fontWeight: '400',
                margin: '0 0 8px 0',
              }}
            >
              Submit feedback & Stay
            </div>
            <div
              onClick={cancel_feedback}
              style={{
                border: `1px solid ${data?.userProfileCustomization?.logOutButtonColor}`,
                cursor: 'pointer',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: data?.userProfileCustomization?.logOutButtonColor,
                fontWeight: '400',
                margin: '8px 0 0 0',
              }}
            >
              Submit feedback & Cancel
            </div>
          </div>
        </Modal>
      }

      {/* SEE ALL BENEFITS SCREEN */}
      {
        <Modal
          className={style['container']}
          closable={true}
          centered
          open={seeAllBenefits}
          style={{
            fontSize: '20px',
            fontWeight: '700',
            height: '426px',
          }}
          width={'400px'}
          onCancel={() => {
            setSeeAllBenefits(false);
          }}
          closeIcon={<CrossIcon />}
        >
          <div style={{ padding: '20px' }}>
            <div style={{ textDecoration: 'underline' }}>Benefits</div>
            <div style={{}}>
              <ul style={{ listStyle: 'none' }}>
                <div style={{ marginTop: '15px', fontWeight: 'normal' }}>
                  {benefitArray?.map((e) => (
                    <li style={{ display: 'flex' }}>
                      <div style={{ display: 'flex', alignItems: 'center', height: '24px' }}>
                        <Tick style={{ marginRight: '10px' }} />
                      </div>
                      <div>{e}</div>
                    </li>
                  ))}
                  {/* <li style={{ display: "flex" }}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '24px' }}>
                      <Tick style={{ marginRight: "10px" }} />
                    </div>
                    Exclusive articles and feature
                  </li> */}
                </div>
              </ul>
            </div>
          </div>
        </Modal>
      }

      {/* OPTIONAL SCREEN  */}
      {
        <Modal
          className={style['container']}
          closable={true}
          open={screen5}
          maskClosable={false}
          style={{
            fontSize: '20px',
            height: '426px',
            fontWeight: '700',
            opacity: '',
          }}
          title={'Your Support Fuels and Protects Quality Journalism'}
          width={'360px'}
          onCancel={() => {
            closeAll();
          }}
          closeIcon={<CrossIcon />}
        >
          <div style={{ fontWeight: 'normal', padding: '20px' }}>
            <div style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px' }}>Thanks for your feedback. </div>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
                margin: '20px 0 20px 0',
                borderRadius: '7px',
              }}
            >
              We do not want to lose you, Does it help if we extend your subscription by one month at no additional
              cost?{' '}
            </div>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
                margin: '20px 0 40px 0',
                borderRadius: '7px',
              }}
            >
              It's on us. <br />
              <br /> <br />
            </div>
            <div
              onClick={() => {
                closeAll();
              }}
              style={{
                cursor: 'pointer',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: data?.userProfileCustomization?.logOutButtonColor,
                color: '#fff',
                fontWeight: '400',
                margin: '0 0 8px 0',
              }}
            >
              Claim Offer
            </div>
            <div
              onClick={() => {
                setScreen6(true);
              }}
              style={{
                border: `1px solid ${data?.userProfileCustomization?.logOutButtonColor}`,
                cursor: 'pointer',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: data?.userProfileCustomization?.logOutButtonColor,
                fontWeight: '400',
                margin: '8px 0 0 0',
              }}
            >
              Continue with cancellation
            </div>
          </div>
        </Modal>
      }

      {
        <Modal
          className={style['container']}
          closable={true}
          open={screen6}
          maskClosable={false}
          style={{
            fontSize: '20px',
            fontWeight: '700',
            height: '426px',
            opacity: '',
          }}
          title={'Stay With Us, Keep Reading!'}
          width={'360px'}
          onCancel={() => {
            closeAll();
          }}
          closeIcon={<CrossIcon />}
        >
          <div style={{ fontWeight: 'normal', padding: '20px' }}>
            <div style={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px' }}>
              Dear {'User name'},<br />
              Thanks for your feedback. <br />
              <br />
              Would you like to see how it goes for another month, before cancelling your subscription?
            </div>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
                margin: '20px 0 40px 0',
                padding: '15px 10px',
                borderRadius: '7px',
              }}
            >
              <br />
              <br />
              <br />
            </div>
            <div
              onClick={() => {
                closeAll();
              }}
              style={{
                cursor: 'pointer',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: data?.userProfileCustomization?.logOutButtonColor,
                color: '#fff',
                fontWeight: '400',
                margin: '0 0 8px 0',
              }}
            >
              Stay for a Month
            </div>
            <div
              onClick={() => {
                cancelSubscription(cancellationid);
              }}
              style={{
                border: `1px solid ${data?.userProfileCustomization?.logOutButtonColor}`,
                cursor: 'pointer',
                borderRadius: '5px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: data?.userProfileCustomization?.logOutButtonColor,
                fontWeight: '400',
                margin: '8px 0 0 0',
              }}
            >
              Proceed to Cancel
            </div>
          </div>
        </Modal>
      }

      {loading ? (
        <div
          className={style['outercontainer']}
          style={{
            background: color,
            right: rightvaluepassed,
          }}
        >
          <div className={style['loader-container']}>
            <div className={style['loader']}></div>
          </div>
        </div>
      ) : (
        <div
          className={style['outercontainer']}
          style={{
            right: rightvaluepassed,
            background: data?.userProfileCustomization?.backgroundColor || backgroundColor,
            fontFamily: data?.userProfileCustomization?.fontStyle || fontstyle,
            color: data?.userProfileCustomization?.primaryTextColor || textColor,
          }}
        >
          <div
            className={style['head']}
            style={{ borderBottom: `0.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}` }}
          >
            <div className={style['arrow']} onClick={() => setscreen('userdetails')}>
              <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
            </div>
            <div className={style['topic']}>Your Subscriptions</div>
          </div>

          <div className={style['content']}>
            <div className={style['active']}>
              <div className={style["active_heading"]}>Active subscriptions</div> 
              {(active === 0) ? (
                <div className={style['buynow']}>
                  <small style={{ opacity: '0.5' }}>No Active Subscription</small>
                  <Button text="View Plans" color={data?.userProfileCustomization?.highlightColor || highlightColor} />
                </div>
              ) : (
                active?.map((item) => (
                  <div
                    className={style['list']}
                    style={{ background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor }}
                  >
                    <div className={style['list-item']}>
                      <span>Name : </span>
                      <span>{item.name}</span>
                    </div>
                    <div className={style['list-item']}>
                      <span>Next Due : </span>
                      <span>{formatDate(item.expiryDate)}</span>
                    </div>
                    <div className={style['list-item']}>
                      <span>Duration : </span>
                      <span>
                        <span> {item.duration} </span>
                        <span> {item.duration > 1 ? '  Months' : '  Month'} </span>
                      </span>
                    </div>
                    <div className={style['list-item']}>
                      <span>Subscription type : </span>
                      <span>{subscriptionType(item.subscriptionType)}</span>
                    </div>

                    {item.isCancelable && showCancelButton && (
                      <div className={style['list-item']}>
                        <span
                          className={style['cancelsubs']}
                          style={{ color: data?.userProfileCustomization?.highlightColor || highlightColor }}
                          onClick={() => {
                            data?.userProfileCustomization?.showCancelPopUp
                              ? cancellationTrigger(item)
                              : cancelSubscription(item?._id);
                          }}
                        >
                          {/* onClick={() => { console.log(item) }}>   */}
                          Cancel Subscription
                        </span>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>

            <div className={style['inactive']}>

              <div className={style['active_heading']}>{inActive.length > 0 && "In-active subscriptions"}</div>
              
              {inActive?.map(item => (

                <div
                  className={style['list']}
                  style={{ background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor }}
                >
                  <div className={style['list-item']}>
                    <span>Name : </span>
                    <span>{item.name}</span>
                  </div>
                  <div className={style['list-item']}>
                    <span>Expired on : </span>
                    <span>{formatDate(item.expiryDate)}</span>
                  </div>
                  <div className={style['list-item']}>
                    <span>Duration : </span>
                    <span>
                      <span> {item.duration} </span>
                      <span> {item.duration > 1 ? '  Months' : '  Month'} </span>
                    </span>
                  </div>
                  <div className={style['list-item']}>
                    <span>Subscription type : </span>
                    <span>{subscriptionType(item.subscriptionType)}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Subscription;
