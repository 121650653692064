import React from 'react';

const Key = ({ fill }) => {
    return (

        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.0769 9.45795C18.7298 9.45795 19.3559 9.71736 19.8175 10.1791C20.2791 10.6409 20.5385 11.2671 20.5385 11.9201M23 11.9201C23.0001 12.6384 22.8431 13.3479 22.5401 13.9991C22.2371 14.6502 21.7953 15.2272 21.2458 15.6895C20.6963 16.1518 20.0523 16.4883 19.3591 16.6753C18.6658 16.8624 17.94 16.8955 17.2326 16.7723C16.7707 16.6927 16.2816 16.7936 15.9502 17.1252L13.7692 19.3067H11.9231V21.1534H10.0769V23H7V20.6872C7 20.1972 7.19446 19.7269 7.54072 19.3814L12.8732 14.0475C13.2047 13.7159 13.3056 13.2267 13.2261 12.7647C13.1097 12.0926 13.1342 11.4037 13.298 10.7417C13.4617 10.0796 13.7612 9.45874 14.1774 8.9185C14.5936 8.37826 15.1175 7.9304 15.7158 7.60329C16.3142 7.27619 16.9739 7.07697 17.6532 7.01827C18.3326 6.95957 19.0167 7.04267 19.6622 7.2623C20.3078 7.48193 20.9007 7.8333 21.4033 8.29414C21.906 8.75498 22.3075 9.31525 22.5823 9.93943C22.8572 10.5636 22.9994 11.2381 23 11.9201Z" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        

);
};

export default Key; 

