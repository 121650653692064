import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const queryParams = new URLSearchParams(window.location.search);

  let location = useLocation();
  const code = localStorage.getItem('code');
  const navigate = useNavigate();
  function callNavigate() {
    navigate(`/login?${queryParams.toString()}`);
  }
  React.useEffect(() => {
    const code = localStorage.getItem('code');

    if (!code) {
      callNavigate();
    }
  }, []);

  if (!code) {
    console.log('sjkdhgfiuwehio');
    // return (
    // callNavigate();
    return;
    // )
  }
  return children;
};

export default ProtectedRoute;
