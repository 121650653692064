import React from 'react';



const User = ({ fill }) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.3178 20.9777C19.6967 20.1554 18.8931 19.4885 17.9704 19.0296C17.0477 18.5707 16.031 18.3323 15.0004 18.3333C13.9699 18.3323 12.9532 18.5707 12.0305 19.0296C11.1078 19.4885 10.3042 20.1554 9.68311 20.9777M20.3178 20.9777C21.5298 19.8997 22.3845 18.4786 22.7704 16.9031C23.1562 15.3275 23.054 13.6719 22.4773 12.1558C21.9006 10.6397 20.8766 9.33469 19.5412 8.41389C18.2058 7.49309 16.6221 7 15 7C13.3779 7 11.7942 7.49309 10.4588 8.41389C9.12336 9.33469 8.0994 10.6397 7.5227 12.1558C6.94599 13.6719 6.84378 15.3275 7.22962 16.9031C7.61546 18.4786 8.47111 19.8997 9.68311 20.9777M20.3178 20.9777C18.8547 22.2828 16.961 23.0028 15.0004 23C13.0396 23.003 11.1464 22.283 9.68311 20.9777M17.6671 12.9998C17.6671 13.7071 17.3862 14.3854 16.8861 14.8855C16.386 15.3856 15.7077 15.6665 15.0004 15.6665C14.2932 15.6665 13.6149 15.3856 13.1148 14.8855C12.6147 14.3854 12.3338 13.7071 12.3338 12.9998C12.3338 12.2926 12.6147 11.6143 13.1148 11.1142C13.6149 10.6141 14.2932 10.3331 15.0004 10.3331C15.7077 10.3331 16.386 10.6141 16.8861 11.1142C17.3862 11.6143 17.6671 12.2926 17.6671 12.9998Z" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    );
  };
  
  export default User; 