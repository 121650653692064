import React from 'react';
{/* <svg width="15" height="20" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 13L1 7L7 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg> */}


const Backvector = ({ fill }) => {
    return (

        <svg width="15" height="20" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 13L1 7L7 1" stroke={fill} stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        

);
};

export default Backvector; 

// stroke-width="1.5"
