import React from 'react';

const Card = ({ fill }) => {
    return (

        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path  d="M5 11H25M5 11.8H25M8.07692 17.4H14.2308M8.07692 19.8H11.1538M7.30769 23H22.6923C23.3043 23 23.8913 22.7471 24.3241 22.2971C24.7569 21.847 25 21.2365 25 20.6V9.4C25 8.76348 24.7569 8.15303 24.3241 7.70294C23.8913 7.25286 23.3043 7 22.6923 7H7.30769C6.69565 7 6.10868 7.25286 5.67591 7.70294C5.24313 8.15303 5 8.76348 5 9.4V20.6C5 21.2365 5.24313 21.847 5.67591 22.2971C6.10868 22.7471 6.69565 23 7.30769 23Z" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        

);
};

export default Card; 
