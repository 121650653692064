import React from "react";
{/* <svg width="35" height="35" viewBox="0 0 20 21" fill="white" xmlns="http://www.w3.org/2000/svg">
<path d="M6.68798 4.08451L7.45654 3.84371C8.17797 3.61831 8.94724 3.98301 9.25653 4.69562L9.87009 6.11524C10.1372 6.73335 9.98938 7.46065 9.50295 7.91286L8.15011 9.17147C8.23297 9.92468 8.49153 10.666 8.92439 11.3954C9.33566 12.1012 9.88725 12.7189 10.5465 13.2119L12.1715 12.6799C12.7879 12.479 13.4586 12.7107 13.8358 13.2546L14.7165 14.5216C15.1558 15.1544 15.0772 16.0267 14.5322 16.5629L13.9479 17.1376C13.3665 17.7095 12.5358 17.9174 11.7679 17.6822C9.95438 17.1278 8.28725 15.482 6.76584 12.745C5.24228 10.0038 4.70443 7.67696 5.153 5.76733C5.34156 4.96372 5.92513 4.32322 6.68798 4.08451Z" fill="#white"/>
</svg> */}

const Male = ({ fill }) => {
    return (

        <svg width="35" height="35" viewBox="0 0 20 21" fill="white" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.68798 4.08451L7.45654 3.84371C8.17797 3.61831 8.94724 3.98301 9.25653 4.69562L9.87009 6.11524C10.1372 6.73335 9.98938 7.46065 9.50295 7.91286L8.15011 9.17147C8.23297 9.92468 8.49153 10.666 8.92439 11.3954C9.33566 12.1012 9.88725 12.7189 10.5465 13.2119L12.1715 12.6799C12.7879 12.479 13.4586 12.7107 13.8358 13.2546L14.7165 14.5216C15.1558 15.1544 15.0772 16.0267 14.5322 16.5629L13.9479 17.1376C13.3665 17.7095 12.5358 17.9174 11.7679 17.6822C9.95438 17.1278 8.28725 15.482 6.76584 12.745C5.24228 10.0038 4.70443 7.67696 5.153 5.76733C5.34156 4.96372 5.92513 4.32322 6.68798 4.08451Z" fill={fill}/>
        </svg>
        

);
};

export default Male; 
