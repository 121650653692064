const checkVariableTypesWithValues = (sampleObject, testObject, errorCallBackFunction) => {
  // Get the property names of variables in both objects
  const sampleVariables = Object.keys(sampleObject);
  const testVariables = Object.keys(testObject);

  // Check if variables in testObject have the same type as those in sampleObject
  for (const variableName of sampleVariables) {
    // Ignore prototype properties and methods
    if (sampleObject.hasOwnProperty(variableName) && !variableName.startsWith('__')) {
      //check if a field is required
      if (sampleObject[variableName].required && !testVariables.includes(variableName)) {
        errorCallBackFunction();
        console.error(`Property ${variableName} is required but is missing in events`);
        return false;
      }

      if (testVariables.includes(variableName)) {
        // check if type is matching

        const sampleVariableType = sampleObject[variableName].type;
        const testVariableType = typeof testObject[variableName];

        // Compare variable types
        if (sampleVariableType !== testVariableType) {
          errorCallBackFunction();
          console.error(
            `type is not matching for ${variableName}, passed value is ${testVariableType} and accepted value is ${sampleVariableType}`,
          );
          return false;
        }

        // check for acceptable values for this variables

        if (!(sampleObject[variableName].values === 'NA')) {
          if (!sampleObject[variableName].values.includes(testObject[variableName])) {
            errorCallBackFunction();
            console.error(
              `This value ${testObject[variableName]} is not accepted, acceptable values are ${sampleObject[variableName].values}`,
            );
            return false;
          }
        }
      }
    }
  }

  // All variable types and values match
  return true;
};

const schema = {
  CLICK: {
    eventLocation: {
      type: 'string',
      required: true,
      values: ['SSO_LOGIN', 'SSO_LOGIN_OTP', 'SSO_SIGNUP', 'SSO_SIGNUP_OTP', 'SAP'],
    },
    clickAction: {
      type: 'string',
      require: true,
      values: [
        'MOBILE',
        'EMAIL',
        'GOOGLE',
        'FACEBOOK',
        'SSO_LOGIN_BTN',
        'SSO_SIGNUP_BTN',
        'PHONE',
        'SSO_LOGIN_OTP_BTN',
        'SSO_SIGNUP_OTP_BTN',
        'SSO_LOGIN_PASSWORD_BTN',
        'SSO_SIGNUP_SET_PASSWORD_BTN',
        'ADD_ADDRESS',
        'ADD_PERSONAL_DATA',
      ],
    },
    clientId: {
      type: 'string',
      required: true,
      values: 'NA',
    },
    contentId: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['PAYWALL'],
      },
    },
    // numOfCta is required in kafka but not send from slp
    numOfCta: {
      type: 'number',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['PAYWALL', 'SRP'],
      },
    },
    paywallId: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['PAYWALL'],
      },
    },
    paywallType: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['PAYWALL'],
      },
    },
    paywallDisplayType: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['PAYWALL'],
      },
    },
    landingPageTitle: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['SLP', 'SRP'],
      },
    },
    landingPageId: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['SLP'],
      },
    },
    url: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['SLP', 'SRP'],
      },
    },
    subPlans: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['SLP'],
      },
    },
    tierId: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['SRP'],
      },
    },
    subId: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['SRP'],
      },
    },
  },
  VIEW: {
    eventLocation: {
      type: 'string',
      required: true,
      values: [
        'SSO_LOGIN',
        'SSO_LOGIN_OTP',
        'SSO_SIGNUP',
        'SSO_SIGNUP_OTP',
        'SSO_LOGIN_PASSWORD',
        'SSO_FORGET_PASSWORD',
        'SSO_FORGET_PASSWORD_OTP',
        'SSO_RESET_PASSWORD',
        'SSO_SIGNUP_SECONDARY_DETAILS',
        'SSO_SIGNUP_SET_PASSWORD',
        'SAP',
      ],
    },
    anonId: {
      type: 'string',
      required: true,
      values: 'NA',
    },
    clientId: {
      type: 'string',
      required: true,
      values: 'NA',
    },
    contentId: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['PAYWALL'],
      },
    },
    contentTitle: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['PAYWALL'],
      },
    },
    url: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['PAGE', 'PAYWALL', 'SLP', 'SUBS_LOGIN', 'POPUP'],
      },
    },
    pageType: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['PAGE'],
      },
    },
    paywallId: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['PAYWALL'],
      },
    },
    paywallType: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['PAYWALL'],
      },
    },
    paywallDisplayType: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['PAYWALL'],
      },
    },
    landingPageTitle: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['SLP'],
      },
    },
    landingPageId: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['SLP'],
      },
    },
    tierId: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['SUBS_LOGIN'],
      },
    },
    popupId: {
      type: 'string',
      required: false,
      values: 'NA',
      requiredWhen: {
        eventLocation: ['POPUP'],
      },
    },
  },
  EXIT: {
    eventLocation: {
      type: 'string',
      required: true,
      values: 'NA',
    },
    clientId: {
      type: 'string',
      required: true,
      values: 'NA',
    },
  },
};

const CSCSendEvent = (args) => {
  args.successCallBackFunction = () => {};
  args.errorCallBackFunction = () => {};
  if (!schema.hasOwnProperty(args.eventType))
    return args.errorCallBackFunction(
      `${args.eventType} not found, these are the acceptable values ${Object.keys(schema)}`,
    );
  const result = checkVariableTypesWithValues(schema[args.eventType], args, args.errorCallBackFunction);

  //TODO: check for all the must have fields if they dont exist throw an error.
  args.userAgent = navigator.userAgent;
  args.isCookieBlocked = +!navigator.cookieEnabled;
  if (result)
    navigator.sendBeacon(
      `${process.env.REACT_APP_COLLECTION_BASE_URL}/event`,
      JSON.stringify({
        messageBody: [args],
      }),
    );
};
export default CSCSendEvent;
